<template>
	<div id="tutoredin-register">
		<div class="login-left">
			<div class="bg">
				<div class="title">Inspiration, Innovation and Discovery</div>
				<div class="desc">Any successful education starts with great tutors. Together with us you will have a reliable and convenient way to have deeper knowledge of English that will be especially useful for you when climbing the learning ladder.</div>
			</div>
		</div>
		<div class="login-right">
			<div class="header">
				<SetLocales type="link" color="gray" hover-color="#04d2c8"></SetLocales>
			</div>
			<div class="login-container">
				<div class="logo"><font>Tutoredin</font><span> _ {{ typeName }}</span></div>
				<el-form ref="loginForm" :model="form" :rules="rules" label-width="0px" style="width: 320px;">
					<el-form-item prop="first_name">
						<el-input v-model="form.first_name" :placeholder="$t('auth.enter-your-name')" clearable></el-input>
					</el-form-item>
					<el-form-item prop="last_name">
						<el-input v-model="form.last_name" :placeholder="$t('auth.enter-your-last-name')" clearable></el-input>
					</el-form-item>
					<el-form-item prop="email">
						<el-input v-model="form.email" :placeholder="$t('auth.enter-email-address')" clearable></el-input>
					</el-form-item>
					<el-form-item prop="mobile">
						<el-input
						v-model="form.mobile"
						:placeholder="$t('auth.enter-mobile-number')"
						class="input-with-select"
						>
						<template #prepend>
							<el-select v-model="form.area_code" placeholder="Select" style="width: 110px">
							    <el-option v-for="item, index in finalAreaCode" :label="item.value" :value="item.value">
							    	{{ $t(item.langName) + " " + item.value }}
								</el-option>
							</el-select>
						</template>
					</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input v-model="form.password" :placeholder="$t('auth.enter-your-login-password')" show-password></el-input>
					</el-form-item>
					<!-- <el-form-item class="diy-el-radio">
						<div>注册类型</div>
						<el-radio-group v-model="form.type" size="mini">
							<el-radio-button v-for="item, index in type" :label="item.value" :key="index">{{ item.name }}</el-radio-button>
						</el-radio-group>
					</el-form-item> -->
					<!-- <el-form-item class="diy-el-radio">
						<div>{{ $t('currency.use') }}</div>
						<el-radio-group v-model="form.currency" size="mini">
							<el-radio-button v-for="item, index in currency" :label="item.value" :key="index">{{ $t(item.langName) }}</el-radio-button>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item class="diy-el-radio">
						<div>{{ $t('country.use') }}</div>
						<el-radio-group v-model="form.country" size="mini">
							<el-radio-button v-for="item, index in finalCountry" :label="item.value" :key="index">{{ $t(item.langName) }}</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item>
						<div class="agree-checkbox" v-if="!isTutoredin">
							<el-checkbox v-model="form.agree"></el-checkbox>
							<div>{{ $t('auth.read-and-agree') }} <el-link type="primary" @click="$refs.user_agreement.open()">{{ $t('Terms of use') }}</el-link> {{ $t('auth.and') }} <el-link type="primary" @click="$refs.privacy_agreement.open()">{{ $t('Privacy Agreement') }}</el-link> </div>
						</div>
						<el-button type="primary" :disabled="!form.agree" style="width: 100%;" @click="onLoginSubmit" auto-insert-space="true">{{ $t('auth.register') }}</el-button>
						<div class="register-fogot">
							<el-link type="primary" @click="$router.push('/login')">{{ $t("auth.alreay-account-now-login") }}</el-link>
							<el-link type="danger" @click="$router.push('/forgot')">{{ $t("forgot.forgot-password") }}?</el-link>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<UserAgreement ref="user_agreement" />
		<PrivacyAgreement ref="privacy_agreement" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { checkToken } from '@/utils'
import AuthApi from '@/api/auth'
import { ElMessageBox, ElMessage } from 'element-plus'
import UserAgreement from '@/components/UserAgreement'
import PrivacyAgreement from '@/components/PrivacyAgreement'
import RegexTool from '@/utils/regex'
import SetLocales from '@/components/SetLocales'
import Config from '@/utils/config'

export default {
	components: {
		UserAgreement,
		PrivacyAgreement,
		SetLocales
	},
	data() {
		return {
			redirect: '/login',
			form: {
				first_name: '',
				last_name: '',
				area_code: '+86',
				mobile: '',
				email: '',
				country: 1,
				// currency: 1,
				type: 1,
				invitation_code: '',
				password: '',
				agree: 0
			},
			rules: {
				first_name: [
					{
						required: true,
						message: this.$t("auth.enter-your-name"),
						trigger: 'blur'
					}
				],
				last_name: [
					{
						required: true,
						message: this.$t("auth.enter-your-last-name"),
						trigger: 'blur'
					}
				],
				mobile: [
					{
						required: true,
						message: this.$t("auth.enter-mobile-number"),
						trigger: 'blur'
					},
					{
						pattern: RegexTool.mobile,
						message: this.$t("auth.enter-right-mobile"),
						trigger: 'blur'
					}
				],
				email: [
					{
						required: true,
						message: this.$t("auth.enter-email-address"),
						trigger: 'blur'
					},
					{
						type: 'email',
						message: this.$t("auth.enter-right-email"),
						trigger: ['blur', 'change'],
					},
				],
				password: [
					{
						required: true,
						message: this.$t("auth.enter-your-login-password"),
						trigger: 'blur'
					}, 
					{
						min: 6,
						max: 16,
						message: this.$t("auth.Login-password-characters"),
						trigger: 'blur'
					}
				]
			},
			// currency: [
			//   { name: "人民币", value: 1, langName: "currency.rmb" },
			//   { name: "英镑", value: 2, langName: "currency.gbp" }
			// ],
			type: [
			  { name: '学生', value: 1, langName: "student" },
			  { name: '老师', value: 2, langName: "teacher" }
			],
			// area_code: [
			//   { name: '中国', value: '+86', langName: "china" },
			//   { name: '英国', value: '+44', langName: "britain" },
			//   { name: '俄罗斯', value: '+7', langName: "russia" }
			// ]
		}
	},
	computed: {
		...mapGetters([
			'webSiteData'
		]),
		typeName() {
			for(let i in this.type) {
				if(this.type[i].value == this.form.type) {
					return this.$t(this.type[i].langName);
				}
			}
		},
		isTutoredin() {
			return Config.isTutoredin();
		},
		finalAreaCode() {
			let areaCode = [];
			if(this.isTutoredin) {
				areaCode.push({ name: '英国', value: '+44', langName: "britain" });
			} else {
				areaCode.push({ name: '俄罗斯', value: '+7', langName: "russia" });
			}
			areaCode.push({ name: '中国', value: '+86', langName: "china" });
			return areaCode;
		},
		finalCountry() {
			let countryList = [];
			if(this.isTutoredin) {
				countryList.push({ name: '英国', value: '2', langName: "britain" });
			} else {
				countryList.push({ name: '俄罗斯', value: '4', langName: "russia" });
			}
			countryList.push({ name: '中国', value: '1', langName: "china" });
			return countryList;
		}
	},
	created() {
		let redirect = this.$route.query.redirect
		if(redirect) {
			this.redirect = redirect
		}
		if(checkToken() === true) {
			this.$router.push({path: this.redirect})
		}

		// 默认老师注册
		if(this.isTutoredin) {
			this.form.type = 2;
		}

		this.form.invitation_code = this.$route.query.code || ''
		if(!!this.form.invitation_code && this.form.invitation_code.substr(0, 2) == 'tu') {
			this.form.type = 2;
			// this.form.area_code = this.isTutoredin ? '+44' : '+7'; // 默认使用国外手机号
			// this.form.currency = 2;
		}
		if(!Config.website('isGuessChina')) {
			this.form.area_code = this.isTutoredin ? '+44' : '+7'; // 默认使用国外手机号
			this.form.country = this.isTutoredin ? 2 : 4;
		}
		if(this.isTutoredin) {
			this.form.agree = 1;
		}
	},
	methods: {
		onLoginSubmit() {
			let mobile = this.form.area_code + this.form.mobile;
			if(!RegexTool.mobile.test(mobile)) {
				this.$message.error(this.$t('auth.Please-enter-mobile-and-emial'))
				return false;
			}

			let that = this
			this.$refs.loginForm.validate((valid) => {
				if(valid) {
					if(this.form.agree != 1) {
						this.$message.error(this.$t('auth.Must-click-agree-checkbox'))
						return false;
					}
					AuthApi.register(this.form).then(res => {
						this.$message.success(this.$t('auth.register-success'));
						setTimeout(function(){
							that.$router.push({path: that.redirect})
						}, 2500)
					});
					// let currency = ''
					// for(let i in this.currency) {
					// 	if(this.currency[i].value == this.form.currency) {
					// 		currency = this.$t(this.currency[i].langName)
					// 		break;
					// 	}
					// }
					// let type = ''
					// for(let i in this.type) {
					// 	if(this.type[i].value == this.form.type) {
					// 		type = this.$t(this.type[i].langName)
					// 		break;
					// 	}
					// }

					// ElMessageBox.confirm(
					// 	this.$t("auth.confirm-string", {currency: currency, type: type}), 
					// 	'Tip', 
					// 	{
					// 	confirmButtonText: this.$t('OK'), 
					// 	cancelButtonText: this.$t('CLOSE'), 
					// 	type: "warning"
					// 	}
					// )
					// .then(() => {
					// 	AuthApi.register(this.form).then(res => {
					// 		this.$message.success(this.$t('auth.register-success'));
					// 		setTimeout(function(){
					// 			that.$router.push({path: that.redirect})
					// 		}, 2500)
					// 	})
					// }).catch(() => {
					// 	// this.$message.error("")
					// })
				} else {
					this.$message.error(this.$t('auth.registration-error'))
					return ;
				}
			})
		},
	},
}
</script>
<style lang="less">
#tutoredin-register {
	-moz-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	height: 100vh;
	min-height: 700px;
	width: 100%;

	.login-left {
		-webkit-flex: 1 1;
		flex: 1 1;

		.bg{
			background-image: url("~@/assets/bg.123456.jpg");
			background-position: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100vh;
			min-height: 700px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			padding: 0 10%;

			.title {
				font-size: 3.5rem;
				color: #FFF;
				line-height: 1.1;
				margin-bottom: 20px;
			}

			.desc {
				font-size: 1rem;
				color: #FFF;
				line-height: 1.65;
			}
		}
	}

	.login-right .header {
		position: absolute;
		right: 15px;
		top: 15px;
	}


	.login-right .login-container {
		text-align: left;
		padding-left: 200px;
		padding-right: 200px;

		.logo {
			line-height: 2;

			font {
				font-size: 42px;
				color: var(--el-color-primary);
			}

			span {
				color: var(--el-color-primary);
			}
		}

		.agree-checkbox {
			display: flex;
			div {
				margin-left: 5px;
			}

			.el-link--inner {
				line-height: 1;
			}
		}

		.register-fogot {
			display: flex;
			margin-top: 12px;
			    justify-content: space-between;

			.el-link--inner {
				line-height: 1;
			}
		}
	}

	.diy-el-radio {
		margin: 0;

		.el-form-item__content {
			display: flex;
			justify-content: space-between;

			.el-radio-button__inner {
				width: 80px;
			}
		}
	}
}
</style>