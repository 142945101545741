<template>
	<div id="tutoredin-forgot">
		<div class="warp">
			<div class="title">{{ $t('auth.account-activation') }}</div>
			<div class="note">{{ note }}</div>
			<div style="margin-top: 2rem;" v-if="ok">
        <a @click="onSuccess" class="link">{{ $t('auth.activation') }}</a>
      </div>
		</div>
	</div>
</template>
<script>
import AuthApi from '@/api/auth'

export default {
  data() {
    return {
    	token: '',
    	ok: false,
    	note: this.$t('auth.click-activation-account')
    }
  },
  created() {
  	this.token = this.$route.query.token || '';
  	if(!!this.token) {
  		this.onRegisterVerify();
  	}
  },
  methods: {
  	onRegisterVerify() {
  		AuthApi.register_confirm({_tk: this.token}).then(res => {
  			this.note = this.$t('Success');
  			this.ok = true;
  		})
  	},
  	onSuccess() {
  		let that = this;
  		AuthApi.register_confirm({_tk: this.token, success: 1}).then(res => {
			this.$message.success(this.$t('auth.activation-success'));
        if(this.$store.getters.isLogin) {
            this.$store.dispatch('GetUserInfo');
        } else {
            this.SetTokenLogin(res).then(res => {
                this.$store.dispatch('GetUserInfo')
            });
        }
        setTimeout(function(){
            that.$router.push({path: '/dashboard'})
        }, 2500)	
		  })
  	}
  },
}
</script>
<style lang="less">
#tutoredin-forgot {
	text-align: center;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
    color: #212529;
    box-sizing: border-box;
    background-color: #fff;

    a {
    	cursor: pointer;
    }

    .warp {
    	display: inline-block;
    	margin-top: 2rem;
    	width: 600px;
    	padding: 3rem 2rem;    
    	margin-bottom: 3rem!important;
    	box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    	border-radius: .25rem!important;
    	background-color: #fff!important;

    	.title {
    		font-size: 22px; 
    		font-weight: bold;
    		color: var(--el-color-primary);
    	}

    	.subTitle {
    		font-size: 18px; 
    		font-weight: bold;
    		color: var(--el-color-info);
    	}

    	.note {
    		margin-top: 1rem !important;
    		text-align: left;
    		color: #555;
    	}

    	.link {
    		text-decoration: none;
    		padding: 12px 65px;
    		border-radius: 5px;
    		background: #f56c6c;
    		color: #FFF;
    		font-size: 15px;
    	}
    }

}

</style>